<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>Modification de la clé maître</h1>
      </header>

      <div class="mb-4">
        <p>
          Remplissez le formulaire ci-dessous pour modifier la clé maître.
        </p>
      </div>

      <div id="update_master_key_form">
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="checkForm"
        >
          <p v-if="successMessage">
            <v-alert
              text
              dense
              color="teal"
              border="left"
              type="success"
            >
              {{ successMessage }}
            </v-alert>
          </p>

          <p v-if="errors.length">
            <v-alert
              text
              dense
              type="error"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error.title }}
            </v-alert>
          </p>

          <v-text-field
            v-model="currentMasterKey"
            label="Clé mâitre actuelle*"
            required
          ></v-text-field>

          <v-text-field
            v-model="newMasterKey"
            label="Nouvelle clé mâitre*"
            required
          ></v-text-field>

          <button type="submit" :disabled="!valid" class="btn btn-primary float-right mt-4">
            <i class="fas fa-check-circle"></i>Valider
          </button>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'UpdateMasterKey',
  data: () => ({
    valid: false,
    successMessage: null,
    errors: [],
    loading: false,
    currentMasterKey: '',
    newMasterKey: ''
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Modification de la clé mâitre'
  },
  methods: {
    checkForm () {
      if (this.valid) {
        this.updateMasterKey()
      }
    },
    updateMasterKey () {
      const data = {
        currentMasterKey: this.currentMasterKey,
        newMasterKey: this.newMasterKey
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/update-master-key', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.successMessage = data
              this.errors = []
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.loading = false

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 403) {
            this.$store.dispatch('logout', 'rejected')
          }
        })
    }
  }
}
</script>
